var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PublicKey } from '@solana/web3.js';
import { Program, AnchorProvider } from '@project-serum/anchor';
import { connection, programID } from './script';
import idl from './rapr_mvp.json';
export function getProgramState() {
    return __awaiter(this, void 0, void 0, function () {
        var provider, program, programState, account, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('Fetching program state');
                    provider = new AnchorProvider(connection, {}, AnchorProvider.defaultOptions());
                    program = new Program(idl, programID, provider);
                    return [4 /*yield*/, PublicKey.findProgramAddress([Buffer.from('program_state')], programID)];
                case 1:
                    programState = (_a.sent())[0];
                    console.log('Program state PDA:', programState.toBase58());
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, program.account.programState.fetch(programState)];
                case 3:
                    account = _a.sent();
                    console.log('Program state fetched:', account);
                    return [2 /*return*/, account];
                case 4:
                    error_1 = _a.sent();
                    console.error('Error fetching program state:', error_1);
                    return [2 /*return*/, null];
                case 5: return [2 /*return*/];
            }
        });
    });
}
export function createPopup(message, onConfirm, onCancel) {
    var overlay = document.createElement('div');
    overlay.className = 'popup-overlay';
    var popup = document.createElement('div');
    popup.className = 'popup-content';
    var messageElement = document.createElement('p');
    messageElement.innerHTML = message;
    messageElement.className = 'popup-message';
    var buttonContainer = document.createElement('div');
    buttonContainer.className = 'popup-button-container';
    var okButton = document.createElement('button');
    okButton.textContent = 'OK';
    okButton.className = 'popup-button';
    okButton.onclick = function () {
        document.body.removeChild(overlay);
        if (onConfirm)
            onConfirm();
    };
    buttonContainer.appendChild(okButton);
    if (onCancel) {
        var cancelButton = document.createElement('button');
        cancelButton.textContent = 'Cancel';
        cancelButton.className = 'popup-button';
        cancelButton.onclick = function () {
            document.body.removeChild(overlay);
            onCancel();
        };
        buttonContainer.appendChild(cancelButton);
    }
    popup.appendChild(messageElement);
    popup.appendChild(buttonContainer);
    overlay.appendChild(popup);
    document.body.appendChild(overlay);
    // Center the popup content
    popup.style.position = 'absolute';
    popup.style.left = '50%';
    popup.style.top = '50%';
    popup.style.transform = 'translate(-50%, -50%)';
    // Prevent scrolling on the body when popup is open
    document.body.style.overflow = 'hidden';
    // Re-enable scrolling when popup is closed
    var removeOverlay = function () {
        document.body.removeChild(overlay);
        document.body.style.overflow = '';
    };
    okButton.onclick = function () {
        removeOverlay();
        if (onConfirm)
            onConfirm();
    };
    if (onCancel) {
        var cancelButton = buttonContainer.lastElementChild;
        cancelButton.onclick = function () {
            removeOverlay();
            onCancel();
        };
    }
}
