var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import { Program, AnchorProvider, web3, BN } from '@project-serum/anchor';
import { TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID, createAssociatedTokenAccountInstruction, getAssociatedTokenAddress } from '@solana/spl-token';
import BigNumber from 'bignumber.js';
import idl from './rapr_mvp.json';
import { getProgramState, createPopup } from './utils';
import { connection, programID, usdcMintAddress, raprMintAddress, wallet } from './script';
var countdownInterval;
export function getUserState(wallet) {
    return __awaiter(this, void 0, void 0, function () {
        var provider, program, userState, account, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('Fetching user state for wallet:', wallet.toBase58());
                    provider = new AnchorProvider(connection, {}, AnchorProvider.defaultOptions());
                    program = new Program(idl, programID, provider);
                    return [4 /*yield*/, PublicKey.findProgramAddress([Buffer.from('user_state'), wallet.toBuffer()], programID)];
                case 1:
                    userState = (_a.sent())[0];
                    console.log('User state PDA:', userState.toBase58());
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, program.account.userState.fetch(userState)];
                case 3:
                    account = _a.sent();
                    console.log('User state fetched:', account);
                    return [2 /*return*/, account];
                case 4:
                    error_1 = _a.sent();
                    console.log('User state not initialized yet');
                    return [2 /*return*/, null];
                case 5: return [2 /*return*/];
            }
        });
    });
}
export function purchaseTickets(wallet, amount) {
    return __awaiter(this, void 0, void 0, function () {
        var provider, program, amountBN, userUsdcAccount, programState, userState, state, purchaseIx, tx, latestBlockhash;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('Initiating ticket purchase for amount:', amount);
                    provider = new AnchorProvider(connection, { publicKey: wallet }, AnchorProvider.defaultOptions());
                    program = new Program(idl, programID, provider);
                    amountBN = new BN(amount);
                    console.log('Amount as BN:', amountBN.toString());
                    return [4 /*yield*/, getAssociatedTokenAddress(usdcMintAddress, wallet)];
                case 1:
                    userUsdcAccount = _a.sent();
                    console.log('User USDC account:', userUsdcAccount.toBase58());
                    return [4 /*yield*/, PublicKey.findProgramAddress([Buffer.from('program_state')], programID)];
                case 2:
                    programState = (_a.sent())[0];
                    console.log('Program state PDA:', programState.toBase58());
                    return [4 /*yield*/, PublicKey.findProgramAddress([Buffer.from('user_state'), wallet.toBuffer()], programID)];
                case 3:
                    userState = (_a.sent())[0];
                    console.log('User state PDA:', userState.toBase58());
                    return [4 /*yield*/, getProgramState()];
                case 4:
                    state = _a.sent();
                    if (!state)
                        throw new Error('Failed to fetch program state');
                    console.log('Creating purchase instruction...');
                    return [4 /*yield*/, program.methods.purchaseTickets(amountBN)
                            .accounts({
                            userState: userState,
                            userUsdcAccount: userUsdcAccount,
                            userAuthority: wallet,
                            usdcTokenAccount: state.usdcTokenAccount,
                            programState: programState,
                            tokenProgram: TOKEN_PROGRAM_ID,
                            systemProgram: SystemProgram.programId,
                        })
                            .instruction()];
                case 5:
                    purchaseIx = _a.sent();
                    tx = new Transaction().add(purchaseIx);
                    tx.feePayer = wallet;
                    return [4 /*yield*/, connection.getLatestBlockhash()];
                case 6:
                    latestBlockhash = _a.sent();
                    tx.recentBlockhash = latestBlockhash.blockhash;
                    console.log('Transaction built:', tx);
                    return [2 /*return*/, tx.serialize({ verifySignatures: false }).toString('base64')];
            }
        });
    });
}
function redeemTickets(wallet) {
    return __awaiter(this, void 0, void 0, function () {
        var provider, program, programState, userStatePDA, treasuryAccount, userRaprAccount, redeemIx, createAccountIx, instructions, tx, latestBlockhash, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    console.log('Initiating ticket redemption');
                    provider = new AnchorProvider(connection, { publicKey: wallet }, AnchorProvider.defaultOptions());
                    program = new Program(idl, programID, provider);
                    return [4 /*yield*/, PublicKey.findProgramAddress([Buffer.from('program_state')], programID)];
                case 1:
                    programState = (_b.sent())[0];
                    console.log('Program state:', programState.toBase58());
                    return [4 /*yield*/, PublicKey.findProgramAddress([Buffer.from('user_state'), wallet.toBuffer()], programID)];
                case 2:
                    userStatePDA = (_b.sent())[0];
                    console.log('User state PDA:', userStatePDA.toBase58());
                    treasuryAccount = new PublicKey('54JSyhobm4StWne5LB3QBhUqd9ua6xMhUDma9zwenEtR');
                    console.log('Treasury account:', treasuryAccount.toBase58());
                    return [4 /*yield*/, getAssociatedTokenAddress(raprMintAddress, wallet, false, TOKEN_2022_PROGRAM_ID)];
                case 3:
                    userRaprAccount = _b.sent();
                    console.log('User RAPR account:', userRaprAccount.toBase58());
                    console.log('Creating redeem instruction...');
                    _b.label = 4;
                case 4:
                    _b.trys.push([4, 8, , 9]);
                    return [4 /*yield*/, program.methods.redeemTickets()
                            .accounts({
                            userState: userStatePDA,
                            userRaprAccount: userRaprAccount,
                            userAuthority: wallet,
                            treasuryAccount: treasuryAccount,
                            programState: programState,
                            raprMint: raprMintAddress,
                            tokenProgram: TOKEN_2022_PROGRAM_ID,
                            systemProgram: SystemProgram.programId,
                        })
                            .instruction()];
                case 5:
                    redeemIx = _b.sent();
                    return [4 /*yield*/, createRaprTokenAccountIfNeeded(wallet)];
                case 6:
                    createAccountIx = _b.sent();
                    instructions = createAccountIx ? [createAccountIx, redeemIx] : [redeemIx];
                    tx = (_a = new Transaction()).add.apply(_a, instructions);
                    tx.feePayer = wallet;
                    return [4 /*yield*/, connection.getLatestBlockhash()];
                case 7:
                    latestBlockhash = _b.sent();
                    tx.recentBlockhash = latestBlockhash.blockhash;
                    console.log('Transaction built:', tx);
                    return [2 /*return*/, tx.serialize({ verifySignatures: false }).toString('base64')];
                case 8:
                    error_2 = _b.sent();
                    console.error('Error creating redeem instruction:', error_2);
                    if (error_2 instanceof Error && error_2.message.includes('custom program error: 0x1770')) {
                        throw new Error('Calculation overflow occurred during redemption. ase try redeeming a smaller amount.');
                    }
                    throw error_2;
                case 9: return [2 /*return*/];
            }
        });
    });
}
function createRaprTokenAccountIfNeeded(wallet) {
    return __awaiter(this, void 0, void 0, function () {
        var userRaprAccount, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAssociatedTokenAddress(raprMintAddress, wallet, false, TOKEN_2022_PROGRAM_ID)];
                case 1:
                    userRaprAccount = _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, connection.getTokenAccountBalance(userRaprAccount)];
                case 3:
                    _a.sent();
                    return [2 /*return*/, null]; // Account exists
                case 4:
                    error_3 = _a.sent();
                    // Account doesn't exist, create it
                    return [2 /*return*/, createAssociatedTokenAccountInstruction(wallet, userRaprAccount, wallet, raprMintAddress, TOKEN_2022_PROGRAM_ID)];
                case 5: return [2 /*return*/];
            }
        });
    });
}
function calculateReward(tickets, rewardRate) {
    console.log('Calculating reward with parameters:');
    console.log('Tickets:', tickets.toString());
    console.log('Reward Rate:', rewardRate.toString());
    var baseAmount = tickets; // 1 ticket = 1 RAPR
    var amountWithDecimals = tickets.mul(new BN(1e9));
    // rewardRate is in basis points (1% = 100 basis points)
    var totalPercentage = new BN(10000).add(rewardRate);
    var totalReward = amountWithDecimals.mul(totalPercentage).div(new BN(10000));
    var additionalReward = totalReward.sub(amountWithDecimals);
    console.log('Base Amount (1:1 RAPR):', baseAmount.toString());
    console.log('Additional Reward:', additionalReward.toString());
    console.log('Total Reward:', totalReward.toString());
    return {
        baseAmount: baseAmount,
        additionalReward: additionalReward,
        totalReward: totalReward
    };
}
function formatTime(seconds) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = seconds % 60;
    return "".concat(hours.toString().padStart(2, '0'), ":").concat(minutes.toString().padStart(2, '0'), ":").concat(remainingSeconds.toString().padStart(2, '0'));
}
function fetchPrice() {
    return __awaiter(this, void 0, void 0, function () {
        var response, priceText, price, error_4, lastKnownPrice;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch('https://retardedapr.com/data/price')];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error("HTTP error! status: ".concat(response.status));
                    }
                    return [4 /*yield*/, response.text()];
                case 2:
                    priceText = _a.sent();
                    price = parseFloat(priceText);
                    if (isNaN(price)) {
                        throw new Error('Invalid price data');
                    }
                    localStorage.setItem('lastKnownPrice', price.toString());
                    return [2 /*return*/, price];
                case 3:
                    error_4 = _a.sent();
                    console.error('Error fetching price:', error_4);
                    lastKnownPrice = localStorage.getItem('lastKnownPrice');
                    if (lastKnownPrice) {
                        console.log('Using last known price:', lastKnownPrice);
                        return [2 /*return*/, parseFloat(lastKnownPrice)];
                    }
                    return [2 /*return*/, 0]; // Or another appropriate default value
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function initializeTicketsCard(wallet) {
    return __awaiter(this, void 0, void 0, function () {
        var ticketsInfo, purchaseAmountInput, purchaseButton, redeemButton, publicKey, programState, userState, currentPrice, ticketPrice, maxTickets, totalCirculatingTickets, ticketRewardRate, content, userTickets, now, purchaseTime, vestingDuration, timeRemaining_1, _a, baseAmount, additionalReward, totalReward, totalRewardUSD, formattedTotalReward, newPurchaseButton, newRedeemButton, error_5;
        var _this = this;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    console.log('Initializing tickets card');
                    ticketsInfo = document.getElementById('ticketsInfo');
                    purchaseAmountInput = document.getElementById('purchaseAmount');
                    purchaseButton = document.getElementById('purchaseButton');
                    redeemButton = document.getElementById('redeemButton');
                    if (countdownInterval) {
                        clearInterval(countdownInterval);
                    }
                    if (!wallet || !wallet.publicKey) {
                        console.log('Wallet not connected');
                        if (ticketsInfo)
                            ticketsInfo.innerHTML = 'Please connect your wallet to view ticket information.';
                        if (purchaseAmountInput)
                            purchaseAmountInput.disabled = true;
                        if (purchaseButton)
                            purchaseButton.disabled = true;
                        if (redeemButton)
                            redeemButton.disabled = true;
                        return [2 /*return*/];
                    }
                    publicKey = wallet.publicKey;
                    if (purchaseAmountInput)
                        purchaseAmountInput.disabled = false;
                    if (purchaseButton)
                        purchaseButton.disabled = false;
                    if (redeemButton)
                        redeemButton.disabled = false;
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 5, , 6]);
                    console.log('Fetching program and user state');
                    return [4 /*yield*/, getProgramState()];
                case 2:
                    programState = _d.sent();
                    return [4 /*yield*/, getUserState(publicKey)];
                case 3:
                    userState = _d.sent();
                    return [4 /*yield*/, fetchPrice()];
                case 4:
                    currentPrice = _d.sent();
                    if (programState && ticketsInfo) {
                        ticketPrice = programState.ticketPrice.toNumber() / 1e6;
                        maxTickets = programState.maxTickets.toNumber();
                        totalCirculatingTickets = programState.totalCirculatingTickets.toNumber();
                        ticketRewardRate = programState.ticketRewardRate.toNumber() / 100;
                        console.log('Program State:', programState);
                        console.log('Ticket Price:', ticketPrice);
                        console.log('Max Tickets:', maxTickets);
                        console.log('Total Circulating Tickets:', totalCirculatingTickets);
                        console.log('Ticket Reward Rate:', ticketRewardRate);
                        content = "Ticket Price: ".concat(ticketPrice.toFixed(2), " USDC\nAvailable Tickets: ").concat(maxTickets - totalCirculatingTickets, " / ").concat(maxTickets, "\nTicket Reward Rate: ").concat(ticketRewardRate.toFixed(2), "%\n");
                        if (userState && userState.tickets.toNumber() > 0) {
                            userTickets = userState.tickets.toNumber();
                            content += "Your Tickets: ".concat(userTickets, "\n");
                            now = Math.floor(Date.now() / 1000);
                            purchaseTime = userState.ticketPurchaseTimestamp.toNumber();
                            vestingDuration = programState.vestingDuration.toNumber();
                            timeRemaining_1 = Math.max(0, purchaseTime + vestingDuration - now);
                            content += "Time to Redeem: <span id=\"countdown\">".concat(formatTime(timeRemaining_1), "</span>\n");
                            _a = calculateReward(userState.tickets, programState.ticketRewardRate), baseAmount = _a.baseAmount, additionalReward = _a.additionalReward, totalReward = _a.totalReward;
                            totalRewardUSD = new BigNumber(totalReward.toString()).div(1e9).times(currentPrice).toFixed(2);
                            formattedTotalReward = new BigNumber(totalReward.toString()).div(1e9).toFixed(9).replace(/\.?0+$/, "");
                            content += "Total R-APR on Redemption: ".concat(formattedTotalReward, "\n(\u2248$").concat(totalRewardUSD, ")");
                            if (redeemButton) {
                                redeemButton.disabled = timeRemaining_1 > 0;
                            }
                            countdownInterval = setInterval(function () {
                                timeRemaining_1 = Math.max(0, timeRemaining_1 - 1);
                                var countdownElement = document.getElementById('countdown');
                                if (countdownElement) {
                                    countdownElement.textContent = formatTime(timeRemaining_1);
                                }
                                if (timeRemaining_1 === 0) {
                                    clearInterval(countdownInterval);
                                    if (redeemButton) {
                                        redeemButton.disabled = false;
                                        createPopup('Your tickets are now ready. Do you want to redeem them now?', function () { return redeemButton.click(); }, function () { return console.log('Redemption deferred by user'); });
                                    }
                                }
                            }, 1000);
                        }
                        else {
                            content += "You have no tickets.";
                            if (redeemButton)
                                redeemButton.disabled = true;
                        }
                        ticketsInfo.innerHTML = content;
                    }
                    if (purchaseButton) {
                        newPurchaseButton = purchaseButton.cloneNode(true);
                        (_b = purchaseButton.parentNode) === null || _b === void 0 ? void 0 : _b.replaceChild(newPurchaseButton, purchaseButton);
                        newPurchaseButton.addEventListener('click', function () { return __awaiter(_this, void 0, void 0, function () {
                            var amount, tx, signedTx, signature, error_6, logs;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!wallet.publicKey) {
                                            console.log('Wallet not connected');
                                            createPopup('Please connect your wallet first.');
                                            return [2 /*return*/];
                                        }
                                        amount = parseFloat(purchaseAmountInput.value);
                                        if (isNaN(amount) || amount <= 0) {
                                            console.log('Invalid purchase amount:', amount);
                                            createPopup('Please enter a valid amount to purchase.');
                                            return [2 /*return*/];
                                        }
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 6, , 7]);
                                        console.log('Initiating ticket purchase for amount:', amount);
                                        return [4 /*yield*/, purchaseTickets(wallet.publicKey, amount)];
                                    case 2:
                                        tx = _a.sent();
                                        console.log('Purchase transaction created:', tx);
                                        return [4 /*yield*/, wallet.signTransaction(Transaction.from(Buffer.from(tx, 'base64')))];
                                    case 3:
                                        signedTx = _a.sent();
                                        console.log('Transaction signed');
                                        return [4 /*yield*/, connection.sendRawTransaction(signedTx.serialize(), {
                                                skipPreflight: true
                                            })];
                                    case 4:
                                        signature = _a.sent();
                                        console.log('Transaction sent. Signature:', signature);
                                        return [4 /*yield*/, connection.confirmTransaction(signature)];
                                    case 5:
                                        _a.sent();
                                        console.log('Transaction confirmed');
                                        createPopup('Tickets purchased successfully!', function () { return initializeTicketsCard(wallet); });
                                        return [3 /*break*/, 7];
                                    case 6:
                                        error_6 = _a.sent();
                                        console.error('Ticket purchase error:', error_6);
                                        if (error_6 instanceof web3.SendTransactionError) {
                                            logs = error_6.logs;
                                            if (logs) {
                                                console.error('Transaction logs:', logs);
                                            }
                                        }
                                        createPopup('Error while purchasing tickets.<br>Please make sure you have USDC.<br>You must redeem before purchasing additional tickets.');
                                        return [3 /*break*/, 7];
                                    case 7: return [2 /*return*/];
                                }
                            });
                        }); });
                    }
                    if (redeemButton) {
                        newRedeemButton = redeemButton.cloneNode(true);
                        (_c = redeemButton.parentNode) === null || _c === void 0 ? void 0 : _c.replaceChild(newRedeemButton, redeemButton);
                        newRedeemButton.addEventListener('click', function () { return __awaiter(_this, void 0, void 0, function () {
                            var tx_1, error_7;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!wallet.publicKey) {
                                            console.log('Wallet not connected');
                                            createPopup('Please connect your wallet first.');
                                            return [2 /*return*/];
                                        }
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, , 4]);
                                        console.log('Confirming ticket redemption');
                                        return [4 /*yield*/, redeemTickets(wallet.publicKey)];
                                    case 2:
                                        tx_1 = _a.sent();
                                        createPopup('You are about to redeem your tickets. Do you want to proceed?', function () { return __awaiter(_this, void 0, void 0, function () {
                                            var signedTx, signature, error_8;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        _a.trys.push([0, 4, , 5]);
                                                        console.log('Redemption transaction created:', tx_1);
                                                        return [4 /*yield*/, wallet.signTransaction(Transaction.from(Buffer.from(tx_1, 'base64')))];
                                                    case 1:
                                                        signedTx = _a.sent();
                                                        console.log('Transaction signed');
                                                        return [4 /*yield*/, connection.sendRawTransaction(signedTx.serialize(), {
                                                                skipPreflight: true
                                                            })];
                                                    case 2:
                                                        signature = _a.sent();
                                                        console.log('Transaction sent. Signature:', signature);
                                                        return [4 /*yield*/, connection.confirmTransaction(signature)];
                                                    case 3:
                                                        _a.sent();
                                                        console.log('Transaction confirmed');
                                                        createPopup('Tickets redeemed successfully!', function () { return initializeTicketsCard(wallet); });
                                                        return [3 /*break*/, 5];
                                                    case 4:
                                                        error_8 = _a.sent();
                                                        console.error('Ticket redemption error:', error_8);
                                                        createPopup("Ensure you hold at least 1 R-APR token for redemption.");
                                                        return [3 /*break*/, 5];
                                                    case 5: return [2 /*return*/];
                                                }
                                            });
                                        }); }, function () {
                                            console.log('Ticket redemption cancelled');
                                            createPopup('Ticket redemption cancelled.');
                                        });
                                        return [3 /*break*/, 4];
                                    case 3:
                                        error_7 = _a.sent();
                                        console.error('Error preparing ticket redemption:', error_7);
                                        createPopup("Ensure you hold at least 1 R-APR token for redemption.");
                                        return [3 /*break*/, 4];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); });
                    }
                    return [3 /*break*/, 6];
                case 5:
                    error_5 = _d.sent();
                    console.error('Error initializing tickets card:', error_5);
                    if (ticketsInfo)
                        ticketsInfo.innerHTML = 'Error loading ticket information. Please try again later.';
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    });
}
// Listen for wallet changes
window.addEventListener('walletChange', function () {
    console.log('Wallet change detected');
    var ticketsCard = document.querySelector('.card[data-index="3"]');
    if (ticketsCard) {
        console.log('Reinitializing tickets card due to wallet change');
        initializeTicketsCard(wallet);
    }
});
