// Meme overlay imports
import overlayRaprBlack from '../assets/memeoverlay/raprblack.png';
import overlayRaprWhite from '../assets/memeoverlay/raprwhite.png';
import overlayRetardedBlack from '../assets/memeoverlay/retardedblack.png';
import overlayRetardedWhite from '../assets/memeoverlay/retardedwhite.png';
import overlayDolphinTech from '../assets/memeoverlay/dolphintech.png';
import overlayFibonacci from '../assets/memeoverlay/fib.png';
import overlayCyberDolphin from '../assets/memeoverlay/cyberdolphin.png';
import overlayRWhite from '../assets/memeoverlay/rwhite.png';
import overlayRBlack from '../assets/memeoverlay/rblack.png';
import overlayDolphinHat from '../assets/memeoverlay/dolphin-hat.png';
import overlayCouncilHat from '../assets/memeoverlay/council-hat.png';
import overlayRaprBeanie from '../assets/memeoverlay/rapr-beanie.png';
import overlayRaprHat from '../assets/memeoverlay/rapr-hat.png';
import overlayPetDolphin from '../assets/memeoverlay/pet-dolphin.png';
export var memeOverlays = {
    'R-APR BLACK': overlayRaprBlack,
    'R-APR WHITE': overlayRaprWhite,
    'RETARDED BLACK': overlayRetardedBlack,
    'RETARDED WHITE': overlayRetardedWhite,
    'DOLPHIN TECH': overlayDolphinTech,
    'FIBONACCI': overlayFibonacci,
    'CYBER DOLPHIN': overlayCyberDolphin,
    'R WHITE': overlayRWhite,
    'R BLACK': overlayRBlack,
    'DOLPHIN HAT': overlayDolphinHat,
    'COUNCIL HAT': overlayCouncilHat,
    'RAPR BEANIE': overlayRaprBeanie,
    'RAPR HAT': overlayRaprHat,
    'PET DOLPHIN': overlayPetDolphin
};
export function initializeMemeCard() {
    initializeImageCard('imageUpload', 'imageCanvas', 'stylesButton', 'downloadButton', memeOverlays);
}
function initializeImageCard(uploadId, canvasId, stylesButtonId, downloadButtonId, overlays) {
    var canvas = document.getElementById(canvasId);
    if (!canvas) {
        console.error("Canvas element with id \"".concat(canvasId, "\" not found"));
        return;
    }
    var ctx = canvas.getContext('2d');
    if (!ctx) {
        console.error("Unable to get 2D context for canvas \"".concat(canvasId, "\""));
        return;
    }
    var imageUpload = document.getElementById(uploadId);
    var downloadButton = document.getElementById(downloadButtonId);
    var stylesButton = document.getElementById(stylesButtonId);
    if (!imageUpload || !downloadButton || !stylesButton) {
        console.error('One or more required elements not found');
        return;
    }
    var currentOverlayIndex = 0;
    var overlayKeys = Object.keys(overlays);
    var currentOverlay = overlayKeys[currentOverlayIndex];
    var originalImage = null;
    stylesButton.addEventListener('click', function () {
        currentOverlayIndex = (currentOverlayIndex + 1) % overlayKeys.length;
        currentOverlay = overlayKeys[currentOverlayIndex];
        if (originalImage)
            drawImageAndOverlay(canvas, ctx);
    });
    imageUpload.addEventListener('change', function (e) {
        var _a;
        var file = (_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (!file)
            return;
        var reader = new FileReader();
        reader.onload = function (event) {
            var _a;
            originalImage = new Image();
            originalImage.onload = function () {
                drawImageAndOverlay(canvas, ctx);
            };
            originalImage.src = (_a = event.target) === null || _a === void 0 ? void 0 : _a.result;
        };
        reader.readAsDataURL(file);
    });
    function drawImageAndOverlay(targetCanvas, targetCtx) {
        if (!originalImage)
            return;
        var aspectRatio = originalImage.width / originalImage.height;
        var containerWidth = targetCanvas.offsetWidth;
        var containerHeight = targetCanvas.offsetHeight;
        // Maintain canvas size
        targetCanvas.width = containerWidth;
        targetCanvas.height = containerHeight;
        var drawWidth, drawHeight;
        if (containerWidth / aspectRatio <= containerHeight) {
            drawWidth = containerWidth;
            drawHeight = containerWidth / aspectRatio;
        }
        else {
            drawHeight = containerHeight;
            drawWidth = containerHeight * aspectRatio;
        }
        var x = (containerWidth - drawWidth) / 2;
        var y = (containerHeight - drawHeight) / 2;
        targetCtx.clearRect(0, 0, containerWidth, containerHeight);
        targetCtx.drawImage(originalImage, x, y, drawWidth, drawHeight);
        var overlayImg = new Image();
        overlayImg.onload = function () {
            var scale = Math.min(drawWidth / overlayImg.width, drawHeight / overlayImg.height);
            var overlayWidth = overlayImg.width * scale;
            var overlayHeight = overlayImg.height * scale;
            var overlayX = x + (drawWidth - overlayWidth) / 2;
            var overlayY = y + (drawHeight - overlayHeight) / 2;
            targetCtx.drawImage(overlayImg, overlayX, overlayY, overlayWidth, overlayHeight);
        };
        overlayImg.onerror = function () {
            console.error("Failed to load overlay image: ".concat(overlays[currentOverlay]));
            alert("Failed to load overlay image. Please try again or choose a different overlay.");
        };
        overlayImg.src = overlays[currentOverlay];
    }
    downloadButton.addEventListener('click', function () {
        if (!originalImage) {
            alert('Please upload an image first.');
            return;
        }
        try {
            // Create a high-resolution canvas for downloading
            var downloadCanvas_1 = document.createElement('canvas');
            downloadCanvas_1.width = originalImage.width;
            downloadCanvas_1.height = originalImage.height;
            var downloadCtx_1 = downloadCanvas_1.getContext('2d');
            if (!downloadCtx_1) {
                throw new Error('Failed to create download canvas context');
            }
            // Draw high-resolution image with overlay
            downloadCtx_1.drawImage(originalImage, 0, 0, originalImage.width, originalImage.height);
            var overlayImg_1 = new Image();
            overlayImg_1.onload = function () {
                var scale = Math.min(downloadCanvas_1.width / overlayImg_1.width, downloadCanvas_1.height / overlayImg_1.height);
                var overlayWidth = overlayImg_1.width * scale;
                var overlayHeight = overlayImg_1.height * scale;
                var overlayX = (downloadCanvas_1.width - overlayWidth) / 2;
                var overlayY = (downloadCanvas_1.height - overlayHeight) / 2;
                downloadCtx_1.drawImage(overlayImg_1, overlayX, overlayY, overlayWidth, overlayHeight);
                // Now that the overlay is drawn, create the blob
                downloadCanvas_1.toBlob(function (blob) {
                    if (!blob) {
                        throw new Error('Failed to create blob');
                    }
                    // Check if the browser supports the download attribute
                    if ('download' in document.createElement('a')) {
                        var url = URL.createObjectURL(blob);
                        var link = document.createElement('a');
                        link.href = url;
                        link.download = 'rapr_custom_image.png';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(url);
                    }
                    else {
                        // Fallback for mobile browsers that don't support the download attribute
                        var url_1 = URL.createObjectURL(blob);
                        window.open(url_1, '_blank');
                        setTimeout(function () {
                            URL.revokeObjectURL(url_1);
                        }, 100);
                    }
                }, 'image/png');
            };
            overlayImg_1.onerror = function () {
                console.error("Failed to load overlay image: ".concat(overlays[currentOverlay]));
                alert("Failed to load overlay image. Please try again or choose a different overlay.");
            };
            overlayImg_1.src = overlays[currentOverlay];
        }
        catch (error) {
            console.error('Error during download process:', error);
            alert('An error occurred while trying to download the image. Please check the console for more details.');
        }
    });
    window.addEventListener('resize', function () {
        if (originalImage)
            drawImageAndOverlay(canvas, ctx);
    });
}
