var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import { Program, AnchorProvider, web3, BN } from '@project-serum/anchor';
import { TOKEN_2022_PROGRAM_ID, createAssociatedTokenAccountInstruction, getAssociatedTokenAddress } from '@solana/spl-token';
import BigNumber from 'bignumber.js';
import idl from './rapr_mvp.json';
import { connection, programID, raprMintAddress, wallet } from './script';
import { getProgramState, createPopup } from './utils';
export function getUserState(wallet) {
    return __awaiter(this, void 0, void 0, function () {
        var provider, program, userState, account, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('Fetching user state for wallet:', wallet.toBase58());
                    provider = new AnchorProvider(connection, {}, AnchorProvider.defaultOptions());
                    program = new Program(idl, programID, provider);
                    return [4 /*yield*/, PublicKey.findProgramAddress([Buffer.from('user_state'), wallet.toBuffer()], programID)];
                case 1:
                    userState = (_a.sent())[0];
                    console.log('User state PDA:', userState.toBase58());
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, program.account.userState.fetch(userState)];
                case 3:
                    account = _a.sent();
                    console.log('User state fetched:', account);
                    return [2 /*return*/, account];
                case 4:
                    error_1 = _a.sent();
                    console.log('User state not initialized yet');
                    return [2 /*return*/, null];
                case 5: return [2 /*return*/];
            }
        });
    });
}
export function getUserRaprBalance(wallet) {
    return __awaiter(this, void 0, void 0, function () {
        var userRaprAccount, accountInfo, balance, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('Fetching RAPR balance for wallet:', wallet.toBase58());
                    return [4 /*yield*/, getAssociatedTokenAddress(raprMintAddress, wallet, false, TOKEN_2022_PROGRAM_ID)];
                case 1:
                    userRaprAccount = _a.sent();
                    console.log('User RAPR account:', userRaprAccount.toBase58());
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, connection.getTokenAccountBalance(userRaprAccount)];
                case 3:
                    accountInfo = _a.sent();
                    balance = accountInfo.value.uiAmount || 0;
                    console.log('User RAPR balance:', balance);
                    return [2 /*return*/, balance];
                case 4:
                    error_2 = _a.sent();
                    console.log('RAPR token account not initialized yet');
                    return [2 /*return*/, 0];
                case 5: return [2 /*return*/];
            }
        });
    });
}
function createRaprTokenAccountIfNeeded(wallet) {
    return __awaiter(this, void 0, void 0, function () {
        var userRaprAccount, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAssociatedTokenAddress(raprMintAddress, wallet, false, TOKEN_2022_PROGRAM_ID)];
                case 1:
                    userRaprAccount = _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, connection.getTokenAccountBalance(userRaprAccount)];
                case 3:
                    _a.sent();
                    return [2 /*return*/, null]; // Account exists
                case 4:
                    error_3 = _a.sent();
                    // Account doesn't exist, create it
                    return [2 /*return*/, createAssociatedTokenAccountInstruction(wallet, userRaprAccount, wallet, raprMintAddress, TOKEN_2022_PROGRAM_ID)];
                case 5: return [2 /*return*/];
            }
        });
    });
}
export function stakeRapr(wallet, amount) {
    return __awaiter(this, void 0, void 0, function () {
        var provider, program, amountBN, userRaprAccount, programState, userState, stakingAccount, preixs, aimaybe, stakeIx, tx, latestBlockhash;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('Initiating RAPR staking for amount:', amount);
                    provider = new AnchorProvider(connection, { publicKey: wallet }, AnchorProvider.defaultOptions());
                    program = new Program(idl, programID, provider);
                    amountBN = new BN(amount * 1e9);
                    console.log('Amount as BN:', amountBN.toString());
                    return [4 /*yield*/, getAssociatedTokenAddress(raprMintAddress, wallet, false, TOKEN_2022_PROGRAM_ID)];
                case 1:
                    userRaprAccount = _a.sent();
                    console.log('User RAPR account:', userRaprAccount.toBase58());
                    return [4 /*yield*/, PublicKey.findProgramAddress([Buffer.from('program_state')], programID)];
                case 2:
                    programState = (_a.sent())[0];
                    console.log('Program state PDA:', programState.toBase58());
                    return [4 /*yield*/, PublicKey.findProgramAddress([Buffer.from('user_state'), wallet.toBuffer()], programID)];
                case 3:
                    userState = (_a.sent())[0];
                    console.log('User state PDA:', userState.toBase58());
                    return [4 /*yield*/, getAssociatedTokenAddress(raprMintAddress, programState, true, TOKEN_2022_PROGRAM_ID)];
                case 4:
                    stakingAccount = _a.sent();
                    preixs = [];
                    return [4 /*yield*/, connection.getAccountInfo(stakingAccount)];
                case 5:
                    aimaybe = _a.sent();
                    if (aimaybe == undefined) {
                        preixs.push(createAssociatedTokenAccountInstruction(wallet, stakingAccount, programState, raprMintAddress, TOKEN_2022_PROGRAM_ID));
                    }
                    console.log('Staking account PDA:', stakingAccount.toBase58());
                    console.log('Building staking instruction...');
                    return [4 /*yield*/, program.methods.stakeRapr(amountBN)
                            .preInstructions(preixs)
                            .accounts({
                            userState: userState,
                            userRaprAccount: userRaprAccount,
                            userAuthority: wallet,
                            stakingAccount: stakingAccount,
                            programState: programState,
                            raprMint: raprMintAddress,
                            tokenProgram: TOKEN_2022_PROGRAM_ID,
                            systemProgram: SystemProgram.programId,
                        })
                            .instruction()];
                case 6:
                    stakeIx = _a.sent();
                    tx = new Transaction().add(stakeIx);
                    tx.feePayer = wallet;
                    return [4 /*yield*/, connection.getLatestBlockhash()];
                case 7:
                    latestBlockhash = _a.sent();
                    tx.recentBlockhash = latestBlockhash.blockhash;
                    console.log('Transaction built:', tx);
                    return [2 /*return*/, tx.serialize({ verifySignatures: false }).toString('base64')];
            }
        });
    });
}
export function unstakeRapr(wallet) {
    return __awaiter(this, void 0, void 0, function () {
        var provider, program, userState, currentTimestamp, stakingDuration, estimatedReward, programState, userStatePDA, userRaprAccount, stakingAccount, treasuryAccount, unstakeIx, createAccountIx, instructions, tx, latestBlockhash, totalUnstakeAmount;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    console.log('Initiating RAPR unstaking');
                    provider = new AnchorProvider(connection, { publicKey: wallet }, AnchorProvider.defaultOptions());
                    program = new Program(idl, programID, provider);
                    return [4 /*yield*/, getUserState(wallet)];
                case 1:
                    userState = _b.sent();
                    if (!userState)
                        throw new Error('User state not found');
                    console.log('User state:', userState);
                    currentTimestamp = Math.floor(Date.now() / 1000);
                    stakingDuration = currentTimestamp - userState.stakingStartTimestamp.toNumber();
                    estimatedReward = calculateStakingReward(new BigNumber(userState.stakedRapr.toString()), new BigNumber(stakingDuration), new BigNumber(userState.stakingRewardRate.toString()));
                    console.log('Estimated reward:', estimatedReward.toString());
                    return [4 /*yield*/, PublicKey.findProgramAddress([Buffer.from('program_state')], programID)];
                case 2:
                    programState = (_b.sent())[0];
                    console.log('Program state PDA:', programState.toBase58());
                    return [4 /*yield*/, PublicKey.findProgramAddress([Buffer.from('user_state'), wallet.toBuffer()], programID)];
                case 3:
                    userStatePDA = (_b.sent())[0];
                    console.log('User state PDA:', userStatePDA.toBase58());
                    return [4 /*yield*/, getAssociatedTokenAddress(raprMintAddress, wallet, false, TOKEN_2022_PROGRAM_ID)];
                case 4:
                    userRaprAccount = _b.sent();
                    console.log('User RAPR account:', userRaprAccount.toBase58());
                    return [4 /*yield*/, getAssociatedTokenAddress(raprMintAddress, programState, true, TOKEN_2022_PROGRAM_ID)];
                case 5:
                    stakingAccount = _b.sent();
                    console.log('Staking account PDA:', stakingAccount.toBase58());
                    treasuryAccount = new PublicKey('54JSyhobm4StWne5LB3QBhUqd9ua6xMhUDma9zwenEtR');
                    console.log('Treasury account:', treasuryAccount.toBase58());
                    console.log('Building unstake instruction...');
                    return [4 /*yield*/, program.methods.unstakeRapr(userState.stakedRapr)
                            .accounts({
                            userState: userStatePDA,
                            userRaprAccount: userRaprAccount,
                            userAuthority: wallet,
                            stakingAccount: stakingAccount,
                            treasuryAccount: treasuryAccount,
                            programState: programState,
                            raprMint: raprMintAddress,
                            tokenProgram: TOKEN_2022_PROGRAM_ID,
                            systemProgram: SystemProgram.programId,
                        })
                            .instruction()];
                case 6:
                    unstakeIx = _b.sent();
                    return [4 /*yield*/, createRaprTokenAccountIfNeeded(wallet)];
                case 7:
                    createAccountIx = _b.sent();
                    instructions = createAccountIx ? [createAccountIx, unstakeIx] : [unstakeIx];
                    tx = (_a = new Transaction()).add.apply(_a, instructions);
                    tx.feePayer = wallet;
                    return [4 /*yield*/, connection.getLatestBlockhash()];
                case 8:
                    latestBlockhash = _b.sent();
                    tx.recentBlockhash = latestBlockhash.blockhash;
                    console.log('Transaction built:', tx);
                    totalUnstakeAmount = new BigNumber(userState.stakedRapr.toString()).plus(estimatedReward);
                    console.log('Estimated total unstake amount (including reward):', totalUnstakeAmount.toFixed(0));
                    return [2 /*return*/, tx.serialize({ verifySignatures: false }).toString('base64')];
            }
        });
    });
}
function calculateStakingReward(amount, stakingDuration, rewardRate) {
    var SECONDS_PER_DAY = new BigNumber(86400);
    // Convert daily reward rate to per-second rate
    var rewardRatePerSecond = rewardRate.times(10000).div(SECONDS_PER_DAY);
    // Calculate reward
    return amount.times(stakingDuration).times(rewardRatePerSecond).div(10000 * 10000);
}
function fetchPrice() {
    return __awaiter(this, void 0, void 0, function () {
        var response, priceText, price, error_4, lastKnownPrice;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch('https://retardedapr.com/data/price')];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error("HTTP error! status: ".concat(response.status));
                    }
                    return [4 /*yield*/, response.text()];
                case 2:
                    priceText = _a.sent();
                    price = parseFloat(priceText);
                    if (isNaN(price)) {
                        throw new Error('Invalid price data');
                    }
                    localStorage.setItem('lastKnownPrice', price.toString());
                    return [2 /*return*/, price];
                case 3:
                    error_4 = _a.sent();
                    console.error('Error fetching price:', error_4);
                    lastKnownPrice = localStorage.getItem('lastKnownPrice');
                    if (lastKnownPrice) {
                        console.log('Using last known price:', lastKnownPrice);
                        return [2 /*return*/, parseFloat(lastKnownPrice)];
                    }
                    return [2 /*return*/, 0]; // Or another appropriate default value
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function calculateDPY(rewardRate) {
    var rewardRateBN = new BigNumber(rewardRate.toString());
    console.log('Raw reward rate:', rewardRateBN.toFixed());
    var amount = new BigNumber(1e9); // 1 RAPR
    var stakingDuration = new BigNumber(86400); // 1 day in seconds
    var reward = calculateStakingReward(amount, stakingDuration, rewardRateBN);
    var dpy = reward.div(amount).times(100);
    console.log('Calculated DPY:', dpy.toFixed(2));
    return parseFloat(dpy.toFixed(2));
}
export function calculateAPY(dpy) {
    return dpy * 365;
}
export function initializeStakingCard(wallet) {
    return __awaiter(this, void 0, void 0, function () {
        var stakingInfo, stakeAmountInput, maxStakeButton, stakeButton, unstakeButton, publicKey, programState, userState_1, userBalance_1, currentDPY_1, currentAPY_1, updateStakingInfo, newMaxStakeButton, newStakeButton, newUnstakeButton, error_5;
        var _this = this;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    console.log('Initializing staking card');
                    stakingInfo = document.getElementById('stakingInfo');
                    stakeAmountInput = document.getElementById('stakeAmount');
                    maxStakeButton = document.getElementById('maxStakeButton');
                    stakeButton = document.getElementById('stakeButton');
                    unstakeButton = document.getElementById('unstakeButton');
                    if (window.rewardUpdateInterval) {
                        clearInterval(window.rewardUpdateInterval);
                    }
                    if (!wallet || !wallet.publicKey) {
                        console.log('Wallet not connected');
                        if (stakingInfo)
                            stakingInfo.innerHTML = 'Please connect your wallet to view staking information.';
                        if (stakeAmountInput)
                            stakeAmountInput.disabled = true;
                        if (maxStakeButton)
                            maxStakeButton.disabled = true;
                        if (stakeButton)
                            stakeButton.disabled = true;
                        if (unstakeButton)
                            unstakeButton.disabled = true;
                        return [2 /*return*/];
                    }
                    publicKey = wallet.publicKey;
                    if (stakeAmountInput)
                        stakeAmountInput.disabled = false;
                    if (maxStakeButton)
                        maxStakeButton.disabled = false;
                    if (stakeButton)
                        stakeButton.disabled = false;
                    if (unstakeButton)
                        unstakeButton.disabled = false;
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 7, , 8]);
                    console.log('Fetching program and user state');
                    return [4 /*yield*/, getProgramState()];
                case 2:
                    programState = _d.sent();
                    return [4 /*yield*/, getUserState(publicKey)];
                case 3:
                    userState_1 = _d.sent();
                    return [4 /*yield*/, getUserRaprBalance(publicKey)];
                case 4:
                    userBalance_1 = _d.sent();
                    if (!(programState && stakingInfo)) return [3 /*break*/, 6];
                    console.log('Program state staking reward rate:', programState.stakingRewardRate.toString());
                    currentDPY_1 = calculateDPY(programState.stakingRewardRate);
                    currentAPY_1 = calculateAPY(currentDPY_1);
                    console.log('Calculated current DPY:', currentDPY_1);
                    console.log('Calculated current APY:', currentAPY_1);
                    updateStakingInfo = function () { return __awaiter(_this, void 0, void 0, function () {
                        var content, userDPY, userAPY, stakedRaprAmount, dailyEarningsRapr, price, dailyEarningsUSD, currentTimestamp, stakingDuration, estimatedReward, rewardInUSD;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    content = "Current DPY: ".concat(currentDPY_1.toFixed(2), "% \n(APR: ").concat(currentAPY_1.toFixed(2), "%)");
                                    if (!(userState_1 && userState_1.stakedRapr.toNumber() > 0)) return [3 /*break*/, 2];
                                    console.log('User state staking reward rate:', userState_1.stakingRewardRate.toString());
                                    userDPY = calculateDPY(userState_1.stakingRewardRate);
                                    userAPY = calculateAPY(userDPY);
                                    console.log('Calculated user DPY:', userDPY);
                                    console.log('Calculated user APY:', userAPY);
                                    stakedRaprAmount = new BigNumber(userState_1.stakedRapr.toString()).div(1e9);
                                    dailyEarningsRapr = stakedRaprAmount.times(userDPY).div(100);
                                    console.log('Staked RAPR amount:', stakedRaprAmount.toFixed(9));
                                    console.log('Calculated daily earnings in RAPR:', dailyEarningsRapr.toFixed(9));
                                    return [4 /*yield*/, fetchPrice()];
                                case 1:
                                    price = _a.sent();
                                    dailyEarningsUSD = dailyEarningsRapr.times(price);
                                    console.log('Price:', price);
                                    console.log('Daily earnings in USD:', dailyEarningsUSD.toFixed(2));
                                    content += "\nYour DPY: ".concat(userDPY.toFixed(2), "%\n(APR: ").concat(userAPY.toFixed(2), "%)\nStaked R-APR: ").concat(stakedRaprAmount.toFixed(9), "\nDaily Earnings: ").concat(dailyEarningsRapr.toFixed(9), " RAPR\n(\u2248$").concat(dailyEarningsUSD.toFixed(2), ")\n");
                                    currentTimestamp = Math.floor(Date.now() / 1000);
                                    stakingDuration = currentTimestamp - userState_1.stakingStartTimestamp.toNumber();
                                    estimatedReward = calculateStakingReward(new BigNumber(userState_1.stakedRapr.toString()), new BigNumber(stakingDuration), new BigNumber(userState_1.stakingRewardRate.toString()));
                                    rewardInUSD = estimatedReward.div(1e9).times(price);
                                    content += "R-APR Reward: <span id=\"raprReward\">".concat(estimatedReward.div(1e9).toFixed(9), "</span>\n(\u2248$<span id=\"usdReward\">").concat(rewardInUSD.toFixed(2), "</span>)");
                                    if (stakeButton)
                                        stakeButton.disabled = true;
                                    if (stakeAmountInput)
                                        stakeAmountInput.disabled = true;
                                    if (maxStakeButton)
                                        maxStakeButton.disabled = true;
                                    return [3 /*break*/, 3];
                                case 2:
                                    content += "\nYou have no tokens staked.\n";
                                    if (stakeButton)
                                        stakeButton.disabled = false;
                                    if (stakeAmountInput)
                                        stakeAmountInput.disabled = false;
                                    if (maxStakeButton)
                                        maxStakeButton.disabled = false;
                                    _a.label = 3;
                                case 3:
                                    content += "\nYour R-APR Balance: ".concat(userBalance_1);
                                    stakingInfo.innerHTML = content;
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    return [4 /*yield*/, updateStakingInfo()];
                case 5:
                    _d.sent();
                    if (userState_1 && userState_1.stakedRapr.toNumber() > 0) {
                        window.rewardUpdateInterval = setInterval(function () { return __awaiter(_this, void 0, void 0, function () {
                            var currentTimestamp, stakingDuration, estimatedReward, rewardElement, price, rewardInUSD, usdRewardElement;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        currentTimestamp = Math.floor(Date.now() / 1000);
                                        stakingDuration = currentTimestamp - userState_1.stakingStartTimestamp.toNumber();
                                        estimatedReward = calculateStakingReward(new BigNumber(userState_1.stakedRapr.toString()), new BigNumber(stakingDuration), new BigNumber(userState_1.stakingRewardRate.toString()));
                                        rewardElement = document.getElementById('raprReward');
                                        if (rewardElement) {
                                            rewardElement.textContent = estimatedReward.div(1e9).toFixed(9);
                                        }
                                        return [4 /*yield*/, fetchPrice()];
                                    case 1:
                                        price = _a.sent();
                                        rewardInUSD = estimatedReward.div(1e9).times(price);
                                        usdRewardElement = document.getElementById('usdReward');
                                        if (usdRewardElement) {
                                            usdRewardElement.textContent = rewardInUSD.toFixed(2);
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); }, 1000);
                    }
                    _d.label = 6;
                case 6:
                    if (maxStakeButton) {
                        newMaxStakeButton = maxStakeButton.cloneNode(true);
                        (_a = maxStakeButton.parentNode) === null || _a === void 0 ? void 0 : _a.replaceChild(newMaxStakeButton, maxStakeButton);
                        newMaxStakeButton.addEventListener('click', function () {
                            console.log('Max stake button clicked');
                            if (stakeAmountInput)
                                stakeAmountInput.value = userBalance_1.toString();
                        });
                    }
                    if (stakeButton) {
                        newStakeButton = stakeButton.cloneNode(true);
                        (_b = stakeButton.parentNode) === null || _b === void 0 ? void 0 : _b.replaceChild(newStakeButton, stakeButton);
                        newStakeButton.addEventListener('click', function () { return __awaiter(_this, void 0, void 0, function () {
                            var amount, tx, signedTx, signature, error_6, logs;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (userState_1 && userState_1.stakedRapr.toNumber() > 0) {
                                            createPopup('You need to unstake first before staking again.');
                                            return [2 /*return*/];
                                        }
                                        amount = parseFloat(stakeAmountInput.value);
                                        if (isNaN(amount) || amount <= 0) {
                                            console.log('Invalid stake amount:', amount);
                                            createPopup('Please enter a valid amount to stake.');
                                            return [2 /*return*/];
                                        }
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 6, , 7]);
                                        console.log('Initiating stake for amount:', amount);
                                        return [4 /*yield*/, stakeRapr(publicKey, amount)];
                                    case 2:
                                        tx = _a.sent();
                                        console.log('Stake transaction created:', tx);
                                        return [4 /*yield*/, wallet.signTransaction(Transaction.from(Buffer.from(tx, 'base64')))];
                                    case 3:
                                        signedTx = _a.sent();
                                        console.log('Transaction signed');
                                        return [4 /*yield*/, connection.sendRawTransaction(signedTx.serialize(), {
                                                skipPreflight: true
                                            })];
                                    case 4:
                                        signature = _a.sent();
                                        console.log('Transaction sent. Signature:', signature);
                                        return [4 /*yield*/, connection.confirmTransaction(signature)];
                                    case 5:
                                        _a.sent();
                                        console.log('Transaction confirmed');
                                        createPopup('Staking successful!', function () { return initializeStakingCard(wallet); });
                                        return [3 /*break*/, 7];
                                    case 6:
                                        error_6 = _a.sent();
                                        console.error('Staking error:', error_6);
                                        if (error_6 instanceof web3.SendTransactionError) {
                                            logs = error_6.logs;
                                            if (logs) {
                                                console.error('Transaction logs:', logs);
                                            }
                                        }
                                        createPopup('Error while staking.<br>Please check the console for details.');
                                        return [3 /*break*/, 7];
                                    case 7: return [2 /*return*/];
                                }
                            });
                        }); });
                    }
                    if (unstakeButton) {
                        newUnstakeButton = unstakeButton.cloneNode(true);
                        (_c = unstakeButton.parentNode) === null || _c === void 0 ? void 0 : _c.replaceChild(newUnstakeButton, unstakeButton);
                        newUnstakeButton.addEventListener('click', function () { return __awaiter(_this, void 0, void 0, function () {
                            var userState_2, currentTimestamp, stakingDuration, estimatedReward, totalUnstakeAmount, error_7;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        console.log('Initiating unstake');
                                        return [4 /*yield*/, getUserState(publicKey)];
                                    case 1:
                                        userState_2 = _a.sent();
                                        if (!userState_2 || userState_2.stakedRapr.isZero()) {
                                            createPopup('You have no staked RAPR to unstake.');
                                            return [2 /*return*/];
                                        }
                                        currentTimestamp = Math.floor(Date.now() / 1000);
                                        stakingDuration = currentTimestamp - userState_2.stakingStartTimestamp.toNumber();
                                        estimatedReward = calculateStakingReward(new BigNumber(userState_2.stakedRapr.toString()), new BigNumber(stakingDuration), new BigNumber(userState_2.stakingRewardRate.toString()));
                                        totalUnstakeAmount = new BigNumber(userState_2.stakedRapr.toString()).plus(estimatedReward);
                                        createPopup("You are about to unstake:<br><br>" +
                                            "".concat(new BigNumber(userState_2.stakedRapr.toString()).div(1e9).toFixed(9), " RAPR<br><br>") +
                                            "with an estimated reward of:<br><br>" +
                                            "".concat(estimatedReward.div(1e9).toFixed(9), " RAPR<br><br>") +
                                            "Total: ".concat(totalUnstakeAmount.div(1e9).toFixed(9), " RAPR<br><br>") +
                                            "Do you want to proceed?", function () { return __awaiter(_this, void 0, void 0, function () {
                                            var tx, signedTx, signature, error_8, logs;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        _a.trys.push([0, 5, , 6]);
                                                        return [4 /*yield*/, unstakeRapr(publicKey)];
                                                    case 1:
                                                        tx = _a.sent();
                                                        console.log('Unstake transaction created:', tx);
                                                        return [4 /*yield*/, wallet.signTransaction(Transaction.from(Buffer.from(tx, 'base64')))];
                                                    case 2:
                                                        signedTx = _a.sent();
                                                        console.log('Transaction signed');
                                                        return [4 /*yield*/, connection.sendRawTransaction(signedTx.serialize(), {
                                                                skipPreflight: true
                                                            })];
                                                    case 3:
                                                        signature = _a.sent();
                                                        console.log('Transaction sent. Signature:', signature);
                                                        return [4 /*yield*/, connection.confirmTransaction(signature)];
                                                    case 4:
                                                        _a.sent();
                                                        console.log('Transaction confirmed');
                                                        createPopup('Unstaking successful!', function () { return initializeStakingCard(wallet); });
                                                        return [3 /*break*/, 6];
                                                    case 5:
                                                        error_8 = _a.sent();
                                                        console.error('Unstaking error:', error_8);
                                                        if (error_8 instanceof web3.SendTransactionError) {
                                                            logs = error_8.logs;
                                                            if (logs && logs.some(function (log) { return log.includes('Error: insufficient funds'); })) {
                                                                createPopup('An error occurred, please tell whiteboy on twitter/tg.');
                                                            }
                                                            else {
                                                                createPopup("An error occurred, please tell whiteboy on twitter/tg.");
                                                            }
                                                        }
                                                        else if (error_8 instanceof Error) {
                                                            createPopup("An error occurred, please tell whiteboy on twitter/tg.");
                                                        }
                                                        else {
                                                            createPopup('An error occurred, please tell whiteboy on twitter/tg.');
                                                        }
                                                        return [3 /*break*/, 6];
                                                    case 6: return [2 /*return*/];
                                                }
                                            });
                                        }); }, function () {
                                            console.log('Unstake cancelled');
                                            createPopup('Unstake cancelled.');
                                        });
                                        return [3 /*break*/, 3];
                                    case 2:
                                        error_7 = _a.sent();
                                        console.error('Error preparing unstake:', error_7);
                                        if (error_7 instanceof Error) {
                                            createPopup("Error preparing unstake: ".concat(error_7.message));
                                        }
                                        else {
                                            createPopup('An unknown error occurred while preparing unstake.');
                                        }
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                    }
                    return [3 /*break*/, 8];
                case 7:
                    error_5 = _d.sent();
                    console.error('Error initializing staking card:', error_5);
                    if (stakingInfo)
                        stakingInfo.innerHTML = '<p>Error loading staking information. Please try again later.</p>';
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    });
}
// Listen for wallet changes
window.addEventListener('walletChange', function () {
    console.log('Wallet change detected');
    var stakingCard = document.querySelector('.card[data-index="4"]');
    if (stakingCard) {
        console.log('Reinitializing staking card due to wallet change');
        initializeStakingCard(wallet);
    }
});
