export var cardContent = [
    {
        title: "STAKING",
        content: "\n        <div class=\"terminal-container\">\n            <div class=\"terminal-content\" id=\"stakingInfo\"></div>\n        </div>\n        <div class=\"bottom-content\">\n            <div class=\"input-container\">\n                <input type=\"number\" id=\"stakeAmount\" step=\"0.000000001\" min=\"0\" max=\"999999999.999999999\" placeholder=\"Stake amount\">\n                <button id=\"maxStakeButton\">MAX</button>\n            </div>\n            <div class=\"button-container\">\n                <button id=\"stakeButton\" class=\"overlay-button\">STAKE</button>\n                <button id=\"unstakeButton\" class=\"overlay-button\">UNSTAKE ALL</button>\n            </div>\n        </div>\n        "
    },
    {
        title: "TICKETS",
        content: "\n        <div class=\"terminal-container\">\n            <div class=\"terminal-content\" id=\"ticketsInfo\"></div>\n        </div>\n        <div class=\"bottom-content\">\n            <div class=\"input-container\">\n                <input type=\"number\" id=\"purchaseAmount\" step=\"0.000001\" min=\"0\" placeholder=\"Purchase amount\">\n            </div>\n            <div class=\"button-container\">\n                <button id=\"purchaseButton\" class=\"overlay-button\">BUY</button>\n                <button id=\"redeemButton\" class=\"overlay-button\">REDEEM</button>\n            </div>\n        </div>\n        "
    },
    {
        title: "MEME",
        content: "\n        <div class=\"card-content meme-content\">\n            <div class=\"top-content\">\n                <label for=\"imageUpload\" class=\"custom-button choose-file\">Choose File</label>\n                <input type=\"file\" id=\"imageUpload\" accept=\"image/*\">\n            </div>\n            <div class=\"image-container\">\n                <canvas id=\"imageCanvas\"></canvas>\n            </div>\n            <div class=\"button-container meme-buttons\">\n                <button id=\"stylesButton\" class=\"overlay-button\">STYLES</button>\n                <button id=\"downloadButton\" class=\"overlay-button\">DOWNLOAD</button>\n            </div>\n        </div>\n        "
    }
];
